<template>
    <div>
        <a v-if="row.item.offering_id"
           href="#"
           @click="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
        >{{ row.item.offering_name }}</a>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>